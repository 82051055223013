<template>
  <el-card>
   <!-- <div slot="header">
      <h3>我的礼物</h3>
    </div> -->
    <el-tabs v-model="tabActive">
      <el-tab-pane label="谁看过我" name="first">
		  
		  <el-form :inline="true" :model="query" class="text-center">
		    <el-form-item>
		      <el-date-picker
		          v-model="query.dateRange"
		          type="daterange"
		          range-separator="至"
		          start-placeholder="开始日期"
		          end-placeholder="结束日期">
		      </el-date-picker>
		    </el-form-item>
		    <el-form-item>
		      <el-input v-model="query.id" placeholder="请输入名称"></el-input>
		    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" icon="el-icon-search">查询</el-button>
		    </el-form-item>
		  </el-form>
		  
		  
		  
		  <el-row :gutter="40">
		    <el-col :span="8" v-for="i in giveData" :key="i">
		      <div class="item"  style="border:0;" >
				  
				  
		        <el-row>
		          <el-col :span="7" class="text-center margin-top"  style="  width: 200px;  height: 210px;"  >
		            <el-image :src="i.h_url" fit="fill" style="width: 100px; height: 130px;" ></el-image>
					<p>{{i.uname}}</p>
					<p>{{i.create_time}}</p>
					<p>详情</p>
		          </el-col> 
		        </el-row>
		         
		      </div>
		    </el-col>
		  </el-row>
		  
		  
		  
		  
		  
		<!--  <el-pagination class="text-center"
		      background
		      layout="prev, pager, next"
		      :total="1000">
		  </el-pagination>
		  -->
		  
	  </el-tab-pane>
      <el-tab-pane label="我看过谁" name="second">
		  
		  <el-form :inline="true" :model="query" class="text-center">
		    <el-form-item>
		      <el-date-picker
		          v-model="query.dateRange"
		          type="daterange"
		          range-separator="至"
		          start-placeholder="开始日期"
		          end-placeholder="结束日期">
		      </el-date-picker>
		    </el-form-item>
		    <el-form-item>
		      <el-input v-model="query.id" placeholder="请输入ID"></el-input>
		    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" icon="el-icon-search">查询</el-button>
		    </el-form-item>
		  </el-form>
		  
		  
		 <!-- <el-row :gutter="10" class="content">
		  		  
		    <el-col     span="3" class="text-center margin-bottom" v-for="v in list"     >
		      <el-image @click="goInfo(1)" :src="url"></el-image>
		      <p@click="goInfo(1)" > {{v.name}}  </p>  
		      <p@click="goInfo(1)" > {{v.create_time}} </p> 
		  		  
		    </el-col>
		  		
		  </el-row> -->
		  
		  <el-row :gutter="40">
		    <el-col :span="8" v-for="i in haveData" :key="i">
		      <div class="item" style="border:0;"  >
		       
			   
			   
			   
			   
			   <el-row>
			     <el-col :span="7" class="text-center margin-top"  style="  width: 200px;  height: 210px;"  >
			       <el-image :src="i.h_url" fit="fill" style="width: 100px; height: 130px;" ></el-image>
			   	<p>{{i.uname}}</p>
			   	<p>{{i.create_time}}</p>
			   	<p>详情</p>
			     </el-col> 
			   </el-row>
			   
			   
			   
		      </div>
		    </el-col>
		  </el-row>
		  
		  
		  
		  
		  
		 <!-- <el-pagination class="text-center"
		      background
		      layout="prev, pager, next"
		      :total="1000">
		  </el-pagination> -->
		  
		  
	  </el-tab-pane>
    </el-tabs>

   


  </el-card>
</template>

<script>
  import axios from "axios";
  export default {
    name: "GiftIndex",
    data() {
      return {
        tabActive: 'first',
        query: {},
        url: require("../../../assets/avatar/上官芝.jpg"),
		giveData:[],
		haveData:[],
      }
    },
	created(){ 
		
		
		 
		const params = new URLSearchParams(); 
	    params.append('uid', sessionStorage.getItem('uid'));
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getGiveGift',params)
		.then((response) => {   
			this.giveData = response.data.data; 
		}) 
		.catch(function(error) {
		  console.log(error);
		}); 
		
		const params2 = new URLSearchParams(); 
		params2.append('uid', sessionStorage.getItem('uid'));
 
		axios.post(this.apiUrl+'/api/user/getHaveGift',params2)
		.then((response) => {   
			this.haveData = response.data.data; 
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		
		   
	},
  }
</script>

<style scoped lang="scss">
  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    .item {
      width: 100%;
      min-height: 160px;
      border: 1px solid #cccccc;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;

      .margin-top {
        margin-top: 10px;
      }


      .el-image {
        width: 64px;
        height: 64px;
      }

      h3 {
        margin: 10px 0 5px;

        small {
          font-weight: lighter;
        }
      }

      p {
        font-size: 12px;
        margin: 6px 0px;
      }

      .text-red {
        color: #FF0F0F;
      }

      .desc {
        padding: 0px 10px;
      }
    }

    .el-pagination {
      margin-top: 20px;
    }
  }
</style>
